.login-page {
    max-width: 550px; 
    margin: 0 auto;
}

.third-party-signin {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin: 20px 0 0 0;
}

@media only screen and (max-width: 525px) {
    .third-party-signin {
        flex-direction: column;
        align-items: center;
    }
}

.btn-third-party-signin {
    height: 50px;
}