.app-promo-banner {
  padding: 30px 15px;
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: 1062px) {
    gap: 0;
  }
}

a.free-trial-btn {
  .btn-title {
    font-size: 36px;
  }
  .btn-sub {
    font-size: min(1.6vw, 20px);
    @media (max-width: 767px) {
      font-size: min(4vw, 20px);
    }
  }
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #29167D;
  color: white;
  &:hover {
    color: white;
  }
}

.download-app-right {
  margin-left: 1rem;
}

.kid-friendly-txt {
  font-size: 2.5rem;
}

.app-icon {
  width: 100px;
}

.list-group {
  background: white;
}

.app-link-container {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.app-link-button {
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 10rem;
  color: #29167D;
  border: none;
  height: 70px;
  cursor: pointer;
  z-index: 5;
  white-space: nowrap;
  transition: all .2s ease;

  .fa-apple {
    font-size: 2rem;
    margin-right: 10px;
  }
  .fa-google-play {
    font-size: 1.6rem;
    margin-right: 10px;
  }
  &:hover {
    text-decoration: none;
    transform: scale(1.05);
  }
}

.series-footer-icon {
  max-width: 80%;
  display: block;
  margin: 2rem auto;

  @media (max-width: 700px) {
    max-width: 100%;
  }
}

.description body {
  background: none;
}

.meta-icon {
  min-width: 45px;
  height: 45px;
  border-radius: 100%;
  background-color: #EBF2FF;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}

.kid-friendly-txt {
  font-size: min(5vw, 2rem);
}

.series-container {
  font-family: sniglet;
  .row {
    justify-content: center;
  }
}

.cost-data {
  color: #EE2F2C;
  margin-right: 10px;
}
.strikethrough {
  position: relative;
  &:before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 5px;
    border-top: 1.5px solid;
    border-color: inherit;
    color: black;
  
    -webkit-transform: rotate(339deg);
    -moz-transform: rotate(339deg);
    -ms-transform: rotate(339deg);
    -o-transform: rotate(339deg);
    transform: rotate(339deg);
  }
}
.token-data,
.series-title,
.series-sub {
  color: #29167D;
}

.series-sub {
  margin: 1.5rem 0;
}


@media (max-width: 760px) {
  .app-icon {
    width: min(15vw, 80px);
    min-width: 60px;
  }
  .app-link-button {
    height: 40px;
    padding: 10px;
    font-size: 14px;
    .fa-apple {
      font-size: 1.5rem;
    }
    .fa-google-play {
      font-size: 1.1rem;
    }
  }
}

@media (max-width: 450px) {
  .app-link-button {
    font-size: min(3vw, 12px);
    .fa-apple {
      font-size: 1.2rem;
      margin-right: 5px;
    }
    .fa-google-play {
      font-size: .9rem;
      margin-right: 5px;
    }
  }
  .download-app-right {
    margin-left: .5rem;
  }
}