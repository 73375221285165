@import 'src/theme.scss';

//TODO: Are these needed?
@import url('https://fonts.googleapis.com/css2?family=Sniglet&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,700;1,300;1,700&display=swap');

html,
body {
  font-size: 18px;
  // font-family: "Sniglet", cursive;
  // font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
  //   Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  font-family: 'Quicksand', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6,
.display-1,
.display-2,
.display-3,
.display-4 {
  font-family: 'Sniglet', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.bg-gradient {
  background: rgb(0, 105, 217);
  background: linear-gradient(
                  0deg,
                  $basic 0%,
                  rgba(255, 255, 255, 1) 50%
  );
}
