.storier-safe-section {
  overflow: hidden;
}

.rotate {
  // animation: rotation 40s infinite linear;
  // transition: rotation .5s ease-in-out;
}

.rotate-reverse {
  // animation: rotation 40s infinite linear;
  // animation-direction: reverse;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}