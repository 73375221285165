
$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;

$primary:       #42B0D2;
$secondary:     $gray-600;
$success:       #28a745;
$info:          #17a2b8;
$warning:       #ffc107;
$danger:        #dc3545;
$light:         $gray-100;
$dark:          $gray-800;

$basic: #42B0D2;
$extra: #FFBD00;
$super: #EE2F2C;
$purple: #29167D;


$theme-colors: (
        "primary":    $primary,
        "secondary":  $secondary,
        "success":    $success,
        "info":       $info,
        "warning":    $warning,
        "danger":     $danger,
        "light":      $light,
        "dark":       $dark
);

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";


.btn-primary {
  background-color: $primary;
  color: $white;
}

.btn-soft-primary {
  color: $primary;
  background: rgba(55,125,255,.1);
  border-color: transparent;
}

.btn-soft-danger {
  color: $danger;
  background: rgba(222,68,55,.1);
  border-color: transparent;
}

.btn-soft-danger:hover {
  color: #fff;
  background: #de4437;
  box-shadow: 0 4px 11px rgba(222,68,55,.35);
}