$base-blue: #42B0D2;
$base-grey: #656464;

.account-menu {
  position: relative;
  display: inline-block;
}

.account-menu-button {
  padding: 0;
  border-radius: 30px;
  border-color: $base-blue;
  border-style: solid;
  overflow: hidden;
  height: 45px;
  width: 45px;
}

.account-menu-button:hover {
  border-color: $base-blue;
}

.default-account-icon {
  color: #77C6DF;
  font-size: 38px;
  transform: translateY(4px);
}