@import 'src/theme.scss';
$BLUE: #42B0D2;

#first-visited-modal {
  text-align: center;
  font-family: 'Sniglet';
  max-width: 38rem !important;
  .modal-content {
    border-radius: 2rem;
    border: $BLUE 8px solid;
    padding: 1rem;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: .5rem;
  }

  button {
    font-size: 24px;
    margin: 0 auto;
    max-width: 129px;
    width: 100%;
    border-radius: 3rem;
  }

  .text-yellow {
    color: $extra
  }

  .text-red {
    color: $super
  }
}