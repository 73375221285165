$BASIC_BLUE: #42B0D2;
$EXTRA_YELLOW: #FFBD00;
$SUPER_RED: #EE2F2C;

@mixin assignThemeColor($properties, $darkenAmount:0) {
    @each $property in $properties {
        &.basic {
            #{$property}: darken($color: $BASIC_BLUE, $amount: $darkenAmount)
        }
        &.extra {
            #{$property}: darken($color: $EXTRA_YELLOW, $amount: $darkenAmount)
        }
        &.super {
            #{$property}: darken($color: $SUPER_RED, $amount: $darkenAmount)
        }
    }
}

.content {
    max-width: 800px;
    margin: 30px auto; 
    font-family: 'Sniglet';
}

a[disabled] {
    pointer-events: none;
}

.hdr {
    @include assignThemeColor(color);

    position: relative;
    margin: 50px 0;

    font-size: 24px;

    .circles-svg {
        margin: 0 auto;
        width: fit-content;
    }
}

.page-body {
    max-width: 650px;
    margin: 0 auto;
    h2 {
        text-align: center;
    }

}

.options-container {
    display: flex;
    justify-content: space-evenly;
    margin: 20px 0;

    .opt-btn {
        @include assignThemeColor(border-color color);
        width: 300px;
        height: 300px;

        margin: 0 15px;
    
        border-radius: 50px;
        border-width: 5px;
        border-style: solid;
        
        font-size: 50px;
        background-color: white;
        
        transition: all .5s;
        &:hover {
            font-size: 55px;
        }
        &.selected {
            @include assignThemeColor(background-color);
            @include assignThemeColor(border-color, 10);
            color: white;
        }

        @media (max-width:550px) {
            width: 200px;
            height: 200px;
            font-size: 35px;
            &:hover {
                font-size: 38px;
            }
        }
        @media (max-width: 420px) {
            width: 160px;
            height: 160px;
            font-size: 27px;
            border-radius: 30px;
            &:hover {
                font-size: 30px;
            }
        }
    }
}

.cancel-note {
    @include assignThemeColor(color);
    font-size: 24px;
}

.continue-btn-container {
    margin: 0 auto;
    // width: fit-content;
    max-width: 400px;
    > a {
        @include assignThemeColor(background-color border);
     
        width: 100%;
        height: 70px;
        border-radius: 25px;
        font-size: 24px;
        color: white;
        display: inline-block;
        text-align: center;
        line-height: 70px;
        text-decoration: none;

        transition: all .5s;
        &:hover:not([disabled]) {
            font-size: 26px;
            @include assignThemeColor(background-color, 10);
            text-decoration: none;
            color: white;
        }
        &[disabled] {
            background-color: #8a8a8a;
            color: #c5c5c5
        }
    }
}

.form-group {
    flex-wrap: wrap;
}
