$BLUE: #42B0D2;
$LIGHT_BLUE: lighten($color: $BLUE, $amount: 40);

.storier-navbar {
  box-shadow: 0 1px 10px rgba(151,164,175,.3);
}

.nav-item {

  .login-btn {
    background-color: $LIGHT_BLUE;
    border: none;
    color: $BLUE;
    border-radius: 50px;
    padding: 5px 20px;

    &:hover, &:focus, &:active {
      color: $LIGHT_BLUE !important;
      background-color: $BLUE !important;
    }
  }
}

.navbar-nav {
  > li > a {
    color: white;
    margin-right: 10px;
  
    &:hover {
      color: #77838f;
      text-decoration: none;
    }
  }
}