.token-icon {
    position: absolute;
    bottom: 10px;
    left: 15px;
    width: 20%;
    z-index: 1;
}

.series-card-inner {
    position: relative;
    transition: all .2s ease;
}

.series-card:hover {
    .series-card-inner {
        transform: scale(1.1);
    }
}