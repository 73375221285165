$BLUE: #42B0D2;
$PURPLE: #29167D;

.hdr-img {
    position: relative;
    background-size: cover;
    height: 400px;

    @media (max-width: 900px) {
        height: 200px;
    }
    @media (max-width: 500px) {
        height: 150px;
    }
}

.hdr-icon {
    position: absolute;
    right: 240px;
    width: 329px;
    top: 15px;

    @media (max-width: 1350px) {
        right: 150px;
    }
    @media (max-width: 1100px) {
        right: 80px;
    }
    @media (max-width: 900px) {
        right: 30px;
        width: 200px;
        top: 120px;
    }
    @media (max-width: 500px) {
        right: 15px;
        width: 150px;
        top: 160px;
    }
}

.text-blue {
    color: $BLUE;
}

.gradient {
    background: linear-gradient(180deg, rgba(196, 196, 196, 0) 0%, #42B0D2 83.33%);
}

.we-are-proud-section {
    position: relative;
    max-width: 800px; 
    background-color: #f7f7f7;
    border-radius: 10px;
    margin: 50px auto 150px auto;
    padding-bottom: 20px;

    .we-are-proud-text {
        width: 450px;
        color: $PURPLE;
        font-size: 30px;

        @media (max-width: 600px) {
            width: 100%;
            text-align: center;
        }
    }
    .earth-icon {
        // width: 0;
        width: fit-content;
        position: absolute;
        top: -5%;
        right: 40px;
        
        img {
            height: 110%;
        }

        @media (max-width: 600px) {
            margin: 0 auto;
            position: inherit;
            top: 0;
            right: 0;

            img {
                height: 150px;        
            }
        }
    }
}

.link-btn {    
    border: none;
    background: none;
    font-family: 'Sniglet';
    color: $BLUE;
    padding: 0 !important;
    &:hover {
        text-decoration: underline !important;
    }
}

.hear-from-you-text {
    max-width: 750px;
    color: white;
    padding: 0 15px;
    text-align: center;
}

.btn-main {
    display: block;
    padding: 20px 50px;
    background-color: white;
    color: $BLUE;
    font-weight: bold;
    width: fit-content;
    border-radius: 50px;
    margin-top: 15px;
    margin-left: 15px;
    margin: 0 auto;
    &:hover {
        color: $BLUE;
        text-decoration: none;
    }
}