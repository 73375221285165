
// PLAN COLORS
$BASIC_BLUE: #42B0D2;
$EXTRA_YELLOW: #FFBD00;
$SUPER_RED: #EE2F2C;


.subscribe-page {

    .page-hdr {
        text-align: center;
        color: #29167D;
        margin-bottom: 40px;
        h1 {
            font-size: min(8vw, 60px);
        }
        h3 {
            font-size: min(5vw, 40px);
        }        
    }

    .device-links {
        display: flex;
        flex-direction: row;
        justify-content: center;
        color: white;
        i {
            font-size: 60px;
        }
        a {
            color: white;
        }
    }

    .subscribe-hdr-icon {  
        position: absolute;
        bottom: -10px;
        left: 20px;
        width: 400px;
        
        @media (max-width: 850px) {
            width: 250px;
        }
        @media (max-width: 600px) {
            width: 180px;
        }
        @media (max-width: 450px) {
            width: 130px;
        }
    }
    
    @mixin plan-card($color) {
        width: 350px;
        margin: 0 15px;
        margin-bottom: 40px;
        
        @media (max-width: 400px) {
            width: 250px;
            .card {
                height: 540px !important;
                .plan-body .body-bottom {
                    margin-top: 0px !important;
                }
            }
        }
        
        .card {
            position: relative;
            height: 477px;
            border: 5px solid $color;
            border-radius: 50px;
            font-family: 'Sniglet';
            overflow: hidden;
            margin-bottom: 10px;
            
            .plan-hdr {
                width: 100%;
                color: white;
                margin-top: 25px;
                margin-bottom: 20px;
                font-size: 45px;
                text-align: center;
                background-color: $color;
            }
            .plan-body {
                color: $color;
                > ul {
                    padding-right: 5px;
                    padding-inline-start: 32px;
                }
                .plan-price {
                    text-align: center;
                    font-size: 36px;
                    margin-bottom: 10px;
                }
                .body-bottom {
                    font-size: 18px;
                    text-align: center;
                    margin-top: 50px;
                }
            }
            .plan-button {
                position: absolute;
                width: 100%;
                padding: 10px;
                bottom: 0;
                > a {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 100%;
                    border-radius: 40px;
                    height: 80px;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    color: white;
                    white-space: nowrap;
                    border: none;
                    font-size: min(36px, 5.5vw);
                    text-align: center;
                    background-color: $color;
                }
                > a:hover {
                    text-decoration: none;
                }
            }
        }
        
        .sub-content {
            color: $color;
            text-align: center;
            font-size: 18px;
        }
    }
    
    .plans-section {
        background: linear-gradient(180deg, rgba(196, 196, 196, 0) 16.78%, $BASIC_BLUE 100.11%);
        padding: 20px;

        @media (max-width: 800px) {
            padding: 10px;
        }
    
        .plans-container {
            position: relative;
            max-width: 1300px; 
            margin: 20px auto;
            padding: 30px;
            
            background-color: #FFFFFF;
            border: 5px solid #E8E8E8;
            border-radius: 50px;
            
            @media (max-width: 1200px) {
                width: fit-content;
                padding: 30px 5px;
            }
            
            .plans-row {
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                flex-wrap: wrap;
                
                .basic {
                    @include plan-card($BASIC_BLUE);
                }
                .extra {
                    @include plan-card($EXTRA_YELLOW);
                }
                .super {
                    @include plan-card($SUPER_RED);
                }
            }
            
            .sub-text {
                bottom: 0;
                margin: 0 auto;
                
                font-weight: bold;
                text-align: center;
                color: #29167D;
                font-size: 16px;
            }
        }
    }
    
    @mixin faq-section($color) {
        background-color: $color;
        // padding: 0 1px;
        
        .faq-content {
            margin: auto;
            padding-top: 30px;
            padding-bottom: 30px;
            
            h2 {
                margin: 0 auto;
                max-width: 1083px;
                padding: 0 15px;
            }
            
            .faq-hdr {
                width: 100%;
                margin: 10px 0;
                > div {
                    max-width: 1083px;
                    padding: 0 15px;
                    margin: 0 auto;
                    position: relative;

                    > i {
                        position: absolute;
                        top: 4px;
                    }
                    > div {
                        // display: inline-block;
                        padding-left: 15px;
                        white-space: pre-wrap;
                    }
                }
            }
            
            .faq-body {
                width: 100%;
                background-color: white;
                padding: 10px 0;
                // padding-left: 55px;
                
                div {
                    margin: 0 auto;
                    max-width: 1083px;
                    padding: 0 35px;
                    // margin: 0 auto;
                    color: $color;
                    > p {
                        margin-bottom: 0;
                    }
                }
            }
        }
        
        .faq-hdr, h2 {
            color: white;
        } 
    }

    .faqs {
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        .basic-faqs {
            @include faq-section($BASIC_BLUE);
        }
        .extra-faqs {
            @include faq-section($EXTRA_YELLOW);
        }
        .super-faqs {
            @include faq-section($SUPER_RED);
        }
    }
}

.token-tooltip.tooltip.bs-tooltip-top {
    opacity: 1;
    .arrow::before {
        border-top-color: #464646;
        background-color: white;
    }
    .tooltip-inner {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        max-width: 300px;
        height: 180px;
        border: #464646 solid 3px;
        border-radius: 15px;
        background-color: white;
        color: #464646;
        font-weight: bold;
        .div {
            width: 500px;
        }
    }
}