$BLUE: #42B0D2;
$GREY: #c5c5c5;

.modal-hdr {
    color: $BLUE;
}

.body-text {
    text-align: center;
}

.input-group-append {
    > button {
        background-color: $BLUE;
        border-color: $BLUE;
        &:hover, &:focus, &:active {
            background-color: darken($color: $BLUE, $amount: 10) !important;
            border-color: darken($color: $BLUE, $amount: 10) !important;
        }
    }
    > button.btn-light {
        background-color: $GREY;
        border-color: $GREY;
        &:hover, &:focus, &:active {
            background-color: darken($GREY, $amount: 10) !important;
            border-color: darken($GREY, $amount: 10) !important;
        }
    }
}