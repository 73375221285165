$BLUE: #42B0D2;
$RED: #EE2F2C;

.link-blue {
    color: $BLUE;
    &:hover {
        color: $BLUE;
    }
}

.form-group {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    
    &.checkbox {
        justify-content: center;
    }

    label {
        color: $BLUE;
        font-size: 24px;
        width: 100%;
    }
    
    .input-group {
        display: flex;
        flex-direction: row;
        
        &.checkbox {
            width: 30px;
            margin-right: 20px;
        }
        .input-error {
            font-family: Arial, Helvetica, sans-serif;
            font-size: 14px;
            margin-top: 5px;
            margin-left: 5px;
            color: $RED;
        }
    }
}

.form-text.text-danger {
    width: 100%;
}

.js-form-message {
    display: flex;
    flex-direction: column;

    > div {
        margin: 0 auto;
    }
}

.third-party-signin {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin: 20px 0 0 0;
}

@media only screen and (max-width: 525px) {
    .third-party-signin {
        flex-direction: column;
        align-items: center;
    }
}

.btn-third-party-signin {
    height: 50px;
}