@import 'src/theme.scss';

$BLUE: #42B0D2;
$PURPLE: #29167D;

$STORIER_SAFE_TO_COL: 992px;
$COLOR_BLOCKS_TO_COL: 1170px;

.landing-page {
    .landing-header {
        width: 100%;
        position: relative;
        height: 86vh;

        @supports (-webkit-touch-callout: none) {
            /* specific to iOS devices */
            background-attachment: scroll !important;
        }

        @media(max-width: 1100px) {
            background-position: 84% !important;
        }
        @media(max-height: 700px) {
            height: 83.5vh;
        }
        @media(max-height: 600px) {
            height: 76vh;
        }
        
        .hdr-img {
            width: 100%;
            height: auto;
            max-height: 100%;

            position: fixed;
            z-index: -1;
            &.hidden {
                visibility: hidden;
                position: relative;
            }
        }
        &::after {
            content: '';
            position: absolute;
            display: block;
            bottom: 0;
            height: 60%;
            width: 100%;
            background: linear-gradient(to bottom, transparent 0%, white 100%);
        }
    }
    .landing-hdr-top {
        position: absolute;
        top: 25%;
        left: 25%;
        transform: translate(-25%, -25%) scale(1.8);
        transform-origin: top left;
        z-index: 100;
        display: flex;
        flex-direction: column;
        gap: 10px;
        // width: min(64vw, 700px);
        @media(max-width: 1100px) {
            top: unset;
            bottom: 14rem;
            left: 15%;
            transform: translate(-15%, 0) scale(1.8);
        }
        @media (max-width: 800px) {
            top: unset;
            bottom: 8rem;
            left: 50px;
            transform: translate(0, 0) scale(1.4);
        }
        @media (max-width: 550px) {
            top: unset;
            bottom: 4rem;
            left: 50px;
            transform: translate(0, 0) scale(1.2);
        }
        @media (max-width: 300px) {
            top: unset;
            bottom: 1rem;
            left: 20px;
            transform: translate(0, 0) scale(1);
        }

        @media (max-height: 700px) {
            top: unset;
            bottom: 4rem;
            left: 15%;
            transform: translate(-15%, 0) scale(1.2);
        }
        @media (max-height: 450px) {
            top: unset;
            bottom: 0rem;
            left: 50px;
            transform: translate(0, 0) scale(.9);
        }
        .hdr-text {
            font-family: Sniglet;
            font-size: 96px;
        }
        font-family: Sniglet;
        line-height: 1;
        a {
            padding: 15px 20px;
        }
        h1 {
            line-height: 1;
            margin-bottom: 0;
        }
        .hdr-btn {
            &.top {
                @media(min-width: 801px) {
                    display: none;
                }
            }
            &.bottom {
                @media(max-width: 800px) {
                    display: none;
                }
            }
        }
    }
    .sub-header {
        height: 8.2vh;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow-x: clip;
        overflow-y: visible;
        h2 {
            margin: 0;
            font-size: calc(1vw + 3vmin);
            z-index: 100;            
        }
        @media (max-width: 920px), (max-height: 450px) {
            .circle-icon {
                display: none !important;
            }
        }
        @media (max-height: 800px) {
            height: 7vh;
        }
        // @media (max-height: 650px) {
        //     height: 4vh;
        // }
    }
    .landing-background-container {
        overflow: hidden;
    }
    .landing-background {
        width: 100%;
        min-width: 1000px;
    }
    svg .shadow {
        filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
    }
    .btn-main {
        display: block;
        padding: 20px 50px;
        background-color: $BLUE;
        color: white;
        width: fit-content;
        border-radius: 50px;
        margin-top: 15px;
        margin-left: 15px;
        border: none;
        text-align: center;
        filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.2));
        transition: all .3s ease;
        &:hover {
            text-decoration: none;
            transform: scale(1.1);
        }
    }
    .btn-purple {
        @extend .btn-main;
        background-color: $PURPLE;
    }
    .btn-white-blue {
        @extend .btn-main;
        background-color: white;
        color: $basic;
    }
    .btn-white-yellow {
        @extend .btn-main;
        background-color: white;
        color: $extra;
    }
    .btn-yellow-white {
        @extend .btn-main;
        background-color: $extra;
        color: white;
        @media (max-width: 700px) {
            transform: scale(.75);
        }
    }
    .btn-white-red {
        @extend .btn-main;
        background-color: white;
        color: $super;
    }
    .explore-btn {
        @extend .btn-main;
        padding: 20px;
        background-color: $extra;
        border-radius: 50px;
        color: white;
        font: 'Sniglet';
        font-size: 24px;
        text-transform: uppercase;
        display: flex;
        max-width:fit-content;
        margin: 0 auto;
    }
    .explore-the-library-section {
        padding-top: 100px;
        max-width: none;
        background: linear-gradient(360deg, #E4DEFF 0%, #FFFFFF 10%, #FFFFFF 80%, #E4DEFF 100%);
    }
    .content-row {
        display: flex;
        justify-content: center;
        width: 100%;
        flex-wrap: wrap;
     
        @media(max-width: $COLOR_BLOCKS_TO_COL) {
            display: flex;
            flex-flow: column;
            .order-1 {
                order: 2;
            }
            .order-2 {
                order: 1;
            }
        }
    }
    .content-col {
        width: 50%;
        display: flex;
        @media(max-width: $COLOR_BLOCKS_TO_COL) {
            width: 100%;
            align-self: flex-start;
        }

        .content-col-inner {
            margin: auto 60px;
            align-items: center;

            h2 {
                font-size: clamp(1rem, 2.5vw, 3rem);
            }
            p {
                font-size: clamp(1rem, 1.5vw, 2rem);
            }
            
            a {
                margin-top: 60px;
                @media (max-width: 1226px) {
                    margin-top: 30px;
                }
            }

            @media(max-width: $COLOR_BLOCKS_TO_COL) {
                margin: 80px;
                p {
                    font-size: clamp(1rem, 2vw, 2rem);
                }
            }
            @media(max-width: 575px) {
                margin: 40px;
            }
        }
        img {
            height: intrinsic;
        }
    }

    .storier-safe-section {
        background: linear-gradient(180deg, #E3DDFF 0%, #FDFDFD 12.95%);
        padding: 90px 0;

        @media (max-width: $STORIER_SAFE_TO_COL) {
            .row-1 {
                div:nth-child(2) {
                    order: 3;
                }
                div:nth-child(3) {
                    order: 2;
                }
            }
            .row-2 {
                div:nth-child(1) {
                    order: 2;
                }
                div:nth-child(2) {
                    order: 1;
                }
            }
        }
    }
    .storier-safe-col {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
    }
    .storier-safe-icon-container {
        position: relative;
    }
    .storier-safe-icon {
        margin-bottom: 15px;
        width: 100%;
        @media(max-width: 300px) {
            height: unset;
        }
        @media (min-width: $STORIER_SAFE_TO_COL) {
            top: 10%;
            position:absolute;
        }
    }
    .content-section {
        text-align: center;
        max-width: 500px;
        
        @media (min-width: $STORIER_SAFE_TO_COL) {
            margin: 0 40px;
            margin-top: 50px;

            &.storier-safe-icon {
                position:absolute;
            }

            &.safe-step-2 {
                max-width: 360px;
            }
            &.safe-step-3 {
                max-width: 280px;
            }
            &.safe-step-4 {
                max-width: 350px;
            }
        }


        h3 {
          font-size: 24px;  
        }
        p {
            font-size: 14px;
            font-family: 'Sniglet';
        }
    }

    .text-container {
        margin: 0 auto;
    }

    .center {
        margin: 0 auto;
    }
    .text-purple {
        color: $PURPLE;
    }

    .bkgd-blue {
        background-color: $BLUE;
    }
    .bkgd-yellow {
        background-color: #FFBD00;
    }
    .bkgd-red {
        background-color: #EE2F2C;
    }

    .text-blue { 
        color: $basic
    }
    .text-yellow { 
        color: $extra
    }
    .text-red { 
        color: $super
    }
    .text-purple { 
        color: $purple
    }

    .book-mockup {
        max-width: 100%;
        margin-bottom: 20px;
    }
    #mockup-block {
        img {
        width: 100%;
        }
    }
    #icons-row {
        text-align: center;
        opacity: 0.75;
        > i {
        margin: 0 1rem;
        font-size: 32px;
        cursor: pointer;
        }
    }
}
