@import './src/theme';

@mixin placeholder {
    ::-webkit-input-placeholder {@content}
    :-moz-placeholder           {@content}
    ::-moz-placeholder          {@content}
    :-ms-input-placeholder      {@content}  
}

@mixin plan-selecter($color) {
    width: 84px;
    height: 84px;
    color: white;
    border-radius: 50px;
    border: none;
    background: $color;
    margin: 0 5px;

    &.selected {
        border: solid 5px $purple;
    }
    &:hover {
        background: darken($color, 5%);
    }
}

.welcome-banner {
    background-color: #EE2F2C;
    color: white;
    text-align: center;
    padding: 35px 15px;

    a {
        color: white;
        text-decoration: underline;
        &:hover {
            color: white;
        }
    }
}

.search-controls-container {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 30px;

    @media (max-width: 1000px) {
        text-align: center;    
    }
}

.search-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 300px;

    input {
        margin: 20px 0;
        margin-right: 20px;
        border-radius: 100px;
        text-align: center;
        max-width: 460px;
        border: 5px solid $purple;
        height: 70px;
    }

    @media (max-width: 1000px) {
        flex-direction: column;    
    }

    .plan-selectors {
        display: flex;
        flex-direction: row;

        .plan-selector {
            &.basic {
                @include plan-selecter($basic);
            }
            &.extra {
                @include plan-selecter($extra);
            }
            &.super {
                @include plan-selecter($super);
            }
        }
    }
}

.search-selector-tooltip {
    .arrow::before {
        display: none;
    }
    .tooltip-inner {
        background-color: white;
        color: $purple;
        border: 5px solid $purple;
        font-weight: 500;
    }
}

@include placeholder {
    // font-size: 10px;
    @media (max-width: 450px) {
        font-size: 20px;
    }
}
