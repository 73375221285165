.coverArt {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.coverArt:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.coverArt > img {
  position: absolute;
  height: 100%;
}